import { fetchAPI, safeFetchAPI } from '@/api';
import { getErrorMessage, utils } from '@/helpers';
import { EntryMap } from '@/legacy-types';
import { LoyaltyTemplate, SignupFormViewStats } from '@/types';
import { create } from 'zustand';

export type LoyaltyTemplateStoreState = {
	loaded: boolean;
	statsLoaded: boolean;
	loading: boolean;


	defaultForms: Record<string, string> | undefined;
	defaultFormID: string | undefined;
	globalTemplate: LoyaltyTemplate | undefined;
	loyaltyTemplates: LoyaltyTemplate[];
	statsByForm: EntryMap<SignupFormViewStats> | undefined;

	findLoyaltyTemplate: (id: string) => LoyaltyTemplate | undefined;

	loadSignupFormStats: () => Promise<void>;
	loadLoyaltyTemplates: () => Promise<void>;
	getLoyaltyTemplate: (id: string) => Promise<LoyaltyTemplate | undefined>;
	createLoyaltyTemplate: (template: LoyaltyTemplate) => Promise<LoyaltyTemplate | undefined>;
	updateLoyaltyTemplate: (template: LoyaltyTemplate) => Promise<LoyaltyTemplate | undefined>;
	setArchivedStatus: (templateID: string, archived: boolean) => Promise<void>;
};

export const useLoyaltyTemplateStore = create<LoyaltyTemplateStoreState>((set, get) => ({
	loaded: false,
	loading: true,
	statsLoaded: false,
	defaultFormID: undefined,
	globalTemplate: undefined,
	loyaltyTemplates: [],
	defaultForms: undefined,
	statsByForm: undefined,

	findLoyaltyTemplate: (id: string) => get().loyaltyTemplates.find((template) => template.id === id),

	async loadSignupFormStats() {
		const statsByForm = await fetchAPI<EntryMap<SignupFormViewStats>>('/loyalty/:uid/signupFormStats') ?? undefined;
		set({ statsByForm, statsLoaded: true });
	},

	async loadLoyaltyTemplates() {
		const [loyaltyTemplates, error] = await safeFetchAPI<LoyaltyTemplate[]>('/loyalty/:uid');
		if (error || !loyaltyTemplates) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return;
		}
		const sortedTemplates = loyaltyTemplates.sort((a, b) => Number.parseInt(a.id ?? '') - Number.parseInt(b.id ?? ''));
		const globalTemplate = sortedTemplates[0];

		const defaultForms =
			loyaltyTemplates?.reduce(
				(prev, tmpl) => {
					for (const storeID of tmpl?.defaultStores ?? []) {
						prev[storeID] = tmpl.id ?? '';
					}
					return prev;
				},
				{} as Record<string, string>,
			) ?? {};

		if (defaultForms['-1'] === undefined) {
			defaultForms['-1'] = globalTemplate?.id ?? '';
		}

		const defaultFormID = defaultForms['-1'];

		set({
			defaultForms,
			defaultFormID,
			loyaltyTemplates: sortedTemplates,
			globalTemplate,
			loaded: true,
			loading: false,
		});
	},

	async getLoyaltyTemplate(id) {
		// First check if the template is already loaded
		const template = get().findLoyaltyTemplate(id);
		if (template) {
			return template;
		}
		// If not, fetch it from the API
		const [fetchedTemplate, error] = await safeFetchAPI<LoyaltyTemplate>(`/loyalty/:uid/${id}`);
		if (error || !fetchedTemplate) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return undefined;
		}

		// Add the fetched template to the store
		set((state) => ({ loyaltyTemplates: [...state.loyaltyTemplates, fetchedTemplate] }));

		return fetchedTemplate;
	},

	async createLoyaltyTemplate(template) {
		const [createdTemplate, error] = await safeFetchAPI<LoyaltyTemplate>('/loyalty/:uid', {
			method: 'POST',
			payload: template,
		});

		if (error || !createdTemplate) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return undefined;
		}

		return createdTemplate ?? undefined;
	},

	async updateLoyaltyTemplate(template) {
		if (!template.id) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Template ID is required', placement: 'top' });
			return undefined;
		}
		const [createdTemplate, error] = await safeFetchAPI<LoyaltyTemplate>(`/loyalty/:uid/${template.id}`, {
			method: 'PUT',
			payload: template,
		});

		if (error || !createdTemplate) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return undefined;
		}

		return createdTemplate ?? undefined;
	},

	async setArchivedStatus(id, archived) {
		if (!id) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Template ID is required', placement: 'top' });
			return;
		}
		const { getLoyaltyTemplate, updateLoyaltyTemplate } = get();
		const template = await getLoyaltyTemplate(id);
		if (!template) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Template not found', placement: 'top' });
			return;
		}
		const updatedTemplate = { ...template, archived };
		const updated = await updateLoyaltyTemplate(updatedTemplate);
		if (!updated) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Failed to update template', placement: 'top' });
			return;
		}

		set((state) => ({
			loyaltyTemplates: state.loyaltyTemplates.map((tmpl) => (tmpl.id === id ? updated : tmpl)),
		}));
	},
}));

export default useLoyaltyTemplateStore;
