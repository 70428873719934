import { safeFetchAPI } from '@/api';
import { calculateAge, timeUtils, userUtils, utils } from '@/helpers';
import { Setter } from '@/legacy-types';
import { LoyaltyContact, SignupContactContext, SignupForm, SignupFormField } from '@/types';
import { create } from 'zustand';
import { isEmptyValue } from '../useUserSettingsEditingStore';

export type ContactSignupState = {
	loading: boolean;
	errors: Record<string, string>;

	fieldAnswers: Record<string, any>;

	context: SignupContactContext;

	tokyoSmokeOptinCheck: boolean;
};

export type ContactSignupActions = {
	reset: (createCtx?: CreateContactContext) => void;
	setContact: (contact: Setter<Partial<LoyaltyContact>>) => void;
	setContext: (context: Setter<Partial<SignupContactContext>>) => void;
	createContact: (context: CreateContactContext) => Promise<{
		contact: LoyaltyContact | undefined;
		success: boolean;
	}>;
	setFieldAnswer: (fieldID?: string, value?: any) => void;
	getFieldAnswer: (fieldID: string) => any;
	setTokyoSmokeOptInCheck: (value: boolean) => void;

	validateForm: (context: CreateContactContext) => boolean;
};

export type ContactSignupStore = ContactSignupState & ContactSignupActions;

export type CreateContactContext = {
	isPreview: boolean;
	byStaffMember: boolean; // Are they being created by a staff member? (Staff App view)
	signupForm: Partial<SignupForm>;
	storeID: number;
};

const initalState = (createCtx: CreateContactContext): ContactSignupState => {
	const customerTypeField = createCtx.signupForm.signupFields?.find((field) => field.property === 'customerType');
	const contact: Partial<LoyaltyContact> = {
		customerType: !!customerTypeField ? 2 : 0,
		loyalty: !createCtx.byStaffMember, // Self-signup is always assumed loyalty true
		favoriteStoreID: `${createCtx.storeID}` !== '-1' ? createCtx.storeID : undefined,
	};

	// Client hack to make all staff signups loyalty true
	if (utils.uid === '2839') {
		contact.loyalty = true;
	}

	const fieldAnswers: Record<string, any> = {};
	if (customerTypeField?.id) fieldAnswers[customerTypeField.id] = 2;

	return {
		loading: false,
		fieldAnswers,
		context: {
			contact,
		},
		tokyoSmokeOptinCheck: false,
		errors: {},
	};
};

const defaultCreateCtx: CreateContactContext = {
	byStaffMember: false,
	storeID: -1,
	signupForm: {},
	isPreview: false,
};

export const useContactSignupStore = create<ContactSignupStore>((set, get) => ({
	...initalState(defaultCreateCtx),
	reset: (createCtx?: CreateContactContext) => set(initalState(createCtx ?? defaultCreateCtx)),
	setTokyoSmokeOptInCheck: (value) => set({ tokyoSmokeOptinCheck: value }),
	setContext: (context) => {
		const currContext = get().context;
		const nextContext = typeof context === 'function' ? context(currContext) : context;
		set({ context: nextContext });
	},
	setContact: (contact) => {
		const currContact = get().context.contact ?? {};
		const nextContact = typeof contact === 'function' ? contact(currContact) : contact;
		get().setContext((ctx) => ({ ...ctx, contact: nextContact }));
	},
	setFieldAnswer: (fieldID, value) => {
		if (!fieldID) return;
		set({ fieldAnswers: { ...get().fieldAnswers, [fieldID]: value } });
	},
	getFieldAnswer: (fieldID) => get().fieldAnswers[fieldID],
	createContact: async (createCtx) => {
		const { byStaffMember, signupForm } = createCtx;

		const payload: SignupContactContext = {
			...get().context,
			signupFields: get().fieldAnswers,
			formID: signupForm.id,
			referrer: location.href,
		};

		set({ loading: true });

		// Client hack for 1146 to disable SMS optin for all staff member submissions
		if (byStaffMember && utils.uid === '1146') {
			payload.disableSMS = true;
		}

		if (createCtx.isPreview) {
			set({ loading: false });
			get().reset();
			return { contact: undefined, success: true };
		}

		const [newContact, error] = await safeFetchAPI<LoyaltyContact>(getEndPoint(createCtx), {
			method: 'POST',
			payload,
		});

		const contactID = newContact?.contactID || '';

		if (!contactID || error) {
			utils.toaster?.addToast?.({ type: 'danger', message: error || 'There was an error submitting, please try again', placement: 'top' });
			set({ loading: false });
			return { contact: undefined, success: false };
		}

		// Reset the form
		get().reset();

		return { contact: newContact, success: true };
	},

	validateForm: (context: CreateContactContext) => {
		const errors: Record<string, string> = {};

		const signupFields = context.signupForm?.signupFields ?? [];
		let errorMessage = 'Please fill out all required fields';

		// Loop over the signup fields
		// If the field is required and the value is empty, add an error
		signupFields.forEach((field) => {
			if (!field.id) return;
			if (!field.enabled) return;

			const fieldAnswer = get().getFieldAnswer(field.id);
			const isEmpty = isEmptyValue(fieldAnswer);

			if (field.property === 'termsAndConditions') {
				// Only allow it to be empty if it's staff member
				if (!context.byStaffMember && field.required && isEmpty) {
					const termsError = 'You must agree to the terms and conditions';
					errors[field.id] = termsError;
					errorMessage = termsError;
				}
				return;
			}

			if (field.property === 'signup') return;
			if (field.skipValidation) return;

			if (field.required && isEmpty) {
				errors[field.id] = 'This field is required';
			}

			if (field.property === 'captcha' && field.required) {
				if (fieldAnswer !== true) {
					errors[field.id] = 'Captcha is required';
				}
			}

			// If the field has a validator, we need to validate the value
			if (field.validator && !isEmpty) {
				const error = handleValidator(field, fieldAnswer);
				if (error) {
					errors[field.id] = error;
				}
			}
		});

		if (Object.keys(errors).length > 0) {
			utils.toaster?.addToast?.({ type: 'danger', message: errorMessage, placement: 'top' });
		}

		console.log('errors', errors);

		// Set the errors
		set({ errors });

		return Object.keys(errors).length === 0;
	},
}));

const handleValidator = (field: SignupFormField, fieldAnswer: any): string | undefined => {
	if (!field.validator) return undefined;
	const { comparator, value } = field.validator;
	if (field.property === 'birthDate') {
		return handleAgeValidator(field, fieldAnswer);
	}
	switch (comparator) {
		default:
			return undefined;
	}
};

const handleAgeValidator = (field: SignupFormField, fieldAnswer: any): string | undefined => {
	if (!field.validator) return undefined;
	const { comparator, value } = field.validator;
	const { valid, realDate } = timeUtils.parseDate(fieldAnswer);
	if (!valid) return 'Invalid date';
	const fieldAnswerDate = new Date(realDate);
	const yearsOfAge = calculateAge(fieldAnswerDate);
	switch (comparator) {
		case 'greaterThan':
			return yearsOfAge < value ? `You must be at least ${value} years old to sign up` : undefined;
		default:
			return undefined;
	}
};

function getEndPoint(context: CreateContactContext) {
	const { byStaffMember, signupForm } = context;
	const queries = [];
	if (byStaffMember) queries.push('byStaffMember=true');
	const refCode = new URLSearchParams(location.search).get('refCode');
	if (refCode) queries.push(`refCode=${refCode}`);
	if (utils.isAdmin()) queries.push('debugging=true');
	return `/signup-contact/${byStaffMember ? 'dash' : 'self'}/:uid?${queries.join('&')}`;
}
