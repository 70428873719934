import { asyncWrap, safeFetchAPI } from '@/api';
import { getErrorMessage, userUtils, utils } from '@/helpers';
import { createEntryMap, EntryMap } from '@/legacy-types';
import { cleanForm } from '@/pages/signup-form/signup-form-helpers';
import { LoyaltyTemplate, SignupForm, SignupFormField, SignupFormFieldWithForms, SignupFormViewStats } from '@/types';
import { create } from 'zustand';

export type SignupFormStoreState = {
	loaded: boolean;
	statsLoaded: boolean;
	loading: boolean;

	statsByForm: EntryMap<SignupFormViewStats> | undefined;
	defaultFormID: number | undefined;
	defaultForms: Record<number, number> | undefined;
	globalForm: SignupForm | undefined;
	signupFormsMap: EntryMap<SignupForm> | undefined;
	signupForms: () => SignupForm[] | undefined;

	customFieldsLoaded: boolean;
	customFields: Record<string, SignupFormFieldWithForms> | undefined;

	findSignupForm: (id: number) => SignupForm | undefined;

	// owner.POST("/signup-forms-upgrade/:uid", s.upgradeSignupForms)
	// owner.GET("/signup-forms/all/:uid", s.getSignupForms)
	// api.GET("/signup-forms/:uid", s.getSignupForm)
	// owner.POST("/signup-forms/:uid", s.createSignupForm)
	// owner.PUT("/signup-forms/:uid", s.updateSignupForm)
	// owner.GET("/signup-forms/stats/:uid", s.getLoyaltySignupFormStats)
	// owner.GET("/signup-forms/custom-fields/:uid", s.getCustomSignupFields)
	// owner.PUT("/signup-forms/:uid/:formID", s.archiveSignupForm)
	// adm_super.DELETE("/signup-forms/:uid/:formID", s.deleteSignupForm) // Debugging endpoint

	loadSignupForms: () => Promise<void>;
	loadSignupFormStats: () => Promise<void>;
	upgradeSignupForms: () => Promise<void>;
	addCustomField: (field: SignupFormField) => void;
	setSignupForm: (form: SignupForm) => void;
	getCustomFields: () => Promise<Record<string, SignupFormFieldWithForms> | undefined>;
	getSignupForm: (id: number | GetSignupFormConfig, useUID?: string, config?: GetSignupFormConfig) => Promise<SignupForm | undefined>;
	createSignupForm: (template: SignupForm) => Promise<SignupForm | undefined>;
	updateSignupForm: (template: SignupForm) => Promise<SignupForm | undefined>;
	setArchivedStatus: (templateID: number, archived: boolean) => Promise<void>;
	deleteSignupForm: (templateID: number) => Promise<void>;
};

type GetSignupFormConfig = {
	defaultForm?: boolean;
	useFallback?: boolean;
	formID?: number;
	storeID?: number;
	noCache?: boolean;
};

const sortSignupForms = (signupForms: EntryMap<SignupForm>) => {
	return Object.values(signupForms).sort((a, b) => {
		if (a.isDefault && !b.isDefault) return -1;
		if (!a.isDefault && b.isDefault) return 1;
		const aCreated = a.legacyCreated || a.created || 0;
		const bCreated = b.legacyCreated || b.created || 0;
		return aCreated - bCreated;
	});
};

export const findGlobalDefaultForm = (signupForms: EntryMap<SignupForm>): SignupForm | undefined => {
	let oldestForm: SignupForm | undefined;
	let oldestCreated = 0;
	for (const form of Object.values(signupForms)) {
		if (form.isDefault) {
			return form;
		}
		const created = form.legacyCreated || form.created || 0;
		if (!oldestForm || created < oldestCreated) {
			oldestForm = form;
			oldestCreated = created;
		}
	}
	return oldestForm;
};

export const useSignupFormsStore = create<SignupFormStoreState>((set, get) => ({
	loaded: false,
	loading: true,
	statsLoaded: false,
	defaultFormID: undefined,
	globalForm: undefined,
	defaultForms: undefined,
	statsByForm: undefined,
	signupFormsMap: undefined,
	customFieldsLoaded: false,
	customFields: {},

	signupForms: () => sortSignupForms(get().signupFormsMap ?? {}),
	findSignupForm: (id: number) => get().signupFormsMap?.[id],

	setSignupForm: (form: SignupForm) => {
		if (!form.id) {
			return;
		}
		set((state) => ({
			signupFormsMap: { ...state.signupFormsMap, [form.id ?? '']: form },
		}));
	},

	addCustomField(field) {
		if (!field.id) {
			return;
		}
		set((state) => ({
			customFields: { ...state.customFields, [field.id ?? '']: field as SignupFormFieldWithForms },
		}));
	},

	async getCustomFields() {
		const [customFields, error] = await safeFetchAPI<Record<string, SignupFormFieldWithForms>>('/signup-forms/custom-fields/:uid');
		if (error || !customFields) {
			return undefined;
		}
		set({ customFields, customFieldsLoaded: true });
		return customFields;
	},

	async loadSignupFormStats() {
		const [statsByForm, error] = await safeFetchAPI<EntryMap<SignupFormViewStats>>('/signup-forms/stats/:uid');
		if (error || !statsByForm) {
			return;
		}
		set({ statsByForm, statsLoaded: true });
	},

	async loadSignupForms() {
		const [signupForms, error] = await safeFetchAPI<SignupForm[]>('/signup-forms/all/:uid');
		if (error || !signupForms) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return;
		}
		const formsMap = createEntryMap<SignupForm>(signupForms);
		const globalForm = findGlobalDefaultForm(formsMap);

		// Temp fix for global form defaults not being set
		// if (globalForm && !globalForm.isDefault) {
		// 	globalForm.isDefault = true;
		// 	asyncWrap(async () => {
		// 		const updatedForm = await get().updateSignupForm({
		// 			...globalForm,
		// 			isDefault: true,
		// 		});
		// 		if (updatedForm?.id) {
		// 			formsMap[updatedForm.id] = updatedForm;
		// 		}
		// 	});
		// }

		const defaultForms =
			signupForms?.reduce(
				(prev, tmpl) => {
					for (const storeID of tmpl?.defaultStores ?? []) {
						prev[storeID] = tmpl.id ?? 0;
					}
					return prev;
				},
				{} as Record<number, number>,
			) ?? {};

		if (defaultForms['-1'] === undefined) {
			defaultForms['-1'] = globalForm?.id ?? 0;
		}

		const defaultFormID = defaultForms['-1'];

		set({
			defaultForms,
			defaultFormID,
			signupFormsMap: formsMap,
			globalForm,
			loaded: true,
			loading: false,
		});
	},

	async getSignupForm(id?: number | GetSignupFormConfig, useUID?: string, config: GetSignupFormConfig = {}) {
		if (typeof id === 'object') {
			config = id;
			id = undefined;
		}
		// UID can come from utils, OR path in this case
		const uid = useUID || utils.uid;

		// If formID is < 99999 then it's a legacy signup form
		const isLegacySignupForm = id && id < (utils.isStaging() ? 12345 : 99999);

		// First check if the template is already loaded
		if (id && !config.noCache && !isLegacySignupForm) {
			const foundForm = get().findSignupForm(id);
			if (foundForm) {
				return foundForm;
			}
		}

		// If not, fetch it from the API
		const [form, error] = await safeFetchAPI<SignupForm & { id: number }>(`/signup-forms/${uid}`, {
			queryParams: {
				defaultForm: config.defaultForm,
				useFallback: config.useFallback,
				formID: isLegacySignupForm ? config.formID : id || config.formID,
				legacyID: isLegacySignupForm ? id : undefined,
				storeID: config.storeID,
			},
		});
		if (error || !form) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return undefined;
		}

		const [cleanedForm, changed] = cleanForm(form);

		if (changed && userUtils.hasRoleView('manager')) {
			await get().updateSignupForm(cleanedForm);
		}

		// Add the fetched template to the store
		set((state) => ({ signupFormsMap: { ...state.signupFormsMap, [form.id]: cleanedForm } }));

		return form;
	},

	async createSignupForm(form) {
		const [createdForm, error] = await safeFetchAPI<SignupForm>('/signup-forms/:uid', {
			method: 'POST',
			payload: form,
		});

		if (error || !createdForm) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return undefined;
		}

		return createdForm ?? undefined;
	},

	async updateSignupForm(form) {
		if (!form.id) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Form ID is required', placement: 'top' });
			return undefined;
		}
		const [createdForm, error] = await safeFetchAPI<SignupForm>(`/signup-forms/:uid/${form.id}`, {
			method: 'PUT',
			payload: form,
		});

		if (error || !createdForm) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return undefined;
		}

		return createdForm ?? undefined;
	},

	async setArchivedStatus(id, archived) {
		if (!id) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Form ID is required', placement: 'top' });
			return;
		}
		const form = get().findSignupForm(id);
		if (!form) {
			utils.toaster.addToast?.({ type: 'danger', message: 'Form not found', placement: 'top' });
			return;
		}
		const [_, error] = await safeFetchAPI<undefined>(`/signup-forms/:uid/${id}?archived=${archived}`, {
			method: 'PUT',
		});
		if (error) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return;
		}

		utils.toaster.addToast?.({ type: 'success', message: 'Form archived', placement: 'top' });

		form.archived = archived;

		set((state) => ({
			signupFormsMap: { ...state.signupFormsMap, [id]: form },
		}));
	},

	async upgradeSignupForms() {
		const [, error] = await safeFetchAPI<SignupForm[]>('/signup-forms-upgrade/:uid');
		if (error) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return;
		}
		utils.toaster.addToast?.({ type: 'success', message: 'Signup forms upgraded', placement: 'top' });
	},

	async deleteSignupForm(id) {
		const [, error] = await safeFetchAPI<SignupForm>(`/signup-forms/:uid/${id}`, {
			method: 'DELETE',
		});
		if (error) {
			utils.toaster.addToast?.({ type: 'danger', message: getErrorMessage(error), placement: 'top' });
			return;
		}
		utils.toaster.addToast?.({ type: 'success', message: 'Form deleted', placement: 'top' });
		// Remove from the store
		set((state) => {
			const { [id]: _, ...rest } = state.signupFormsMap ?? {};
			return { signupFormsMap: rest };
		});
	},
}));
