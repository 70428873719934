import { create } from 'zustand';

export type SignupFormsManagerStore = {
	refreshTable?: number;
	setValue: (value: Partial<Omit<SignupFormsManagerStore, 'setValue'>>) => void;
};

export const useSignupFormsTableStore = create<SignupFormsManagerStore>((set, get) => ({
	refreshTable: undefined,
	setValue: (value) => set(value),
}));
